import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/img/bgpolos.jpg";

function Thankyou() {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#f5a2d3] min-h-screen w-full font-amithen space-y-10 px-10"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <span className="flex flex-nowrap text-[35px] w-full justify-center uppercase text-center text-white font-fontadira custom-contour">
        TERIMAKASIH SUDAH HADIR DI ADIRA KICK OFF MEETING 2025
      </span>
    </div>
  );
}

export default Thankyou;
