import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

function Admin() {
  const regions = [
    "HEADOFFICE",
    "SUMBAGUT",
    "SUMBAGTENG",
    "SUMBAGSEL",
    "JABOTABEK 1",
    "JABOTABEK 2",
    "JAWA BARAT",
    "JAWA TENGAH",
    "JAWA TIMUR",
    "BNT",
    "KALIMANTAN",
    "SULTAN",
    "PASIMA",
  ];

  const [data, setData] = useState(
    regions.map((region) => ({
      region,
      attendedParticipants: 0,
      maxParticipants: 0,
      totalParticipants: 0,
      percentage: "0%",
    }))
  );

  const [ws, setWs] = useState(null);

  useEffect(() => {
    const socket = new WebSocket("wss://bnl2z3fq-3201.asse.devtunnels.ms/");

    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established.");

      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ action: "GET_PERCENTAGE" }));
      }
    };

    socket.onmessage = (event) => {
      try {
        const response = JSON.parse(event.data);
        if (response.action === "PERCENTAGE_RESULT") {
          setData((prevData) =>
            prevData.map((item) => {
              const updatedItem = response.data.find(
                (entry) => entry.region === item.region
              );

              return updatedItem
                ? {
                    ...item,
                    percentage: updatedItem.percentage || "0%",
                    attendedParticipants:
                      updatedItem.attendedParticipants ||
                      item.attendedParticipants,
                    totalParticipants:
                      updatedItem.attendedParticipants ||
                      item.attendedParticipants,
                    maxParticipants:
                      updatedItem.maxParticipants || item.maxParticipants,
                  }
                : item;
            })
          );
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error occurred:", error);
    };

    socket.onclose = (event) => {
      if (!event.wasClean) {
        console.error("WebSocket closed unexpectedly:", event);
      } else {
        console.log("WebSocket connection closed cleanly.");
      }
    };

    // Clean up WebSocket connection on component unmount
    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
        console.log("WebSocket connection closed on cleanup.");
      }
    };
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = value;

    // Ensure totalParticipants matches attendedParticipants
    if (field === "attendedParticipants") {
      updatedData[index].totalParticipants = value; // Set totalParticipants to match attendedParticipants
    }

    if (field === "attendedParticipants" || field === "maxParticipants") {
      const attendedParticipants = updatedData[index].attendedParticipants;
      const maxParticipants = updatedData[index].maxParticipants;
      updatedData[index].percentage =
        maxParticipants > 0
          ? `${((attendedParticipants / maxParticipants) * 100).toFixed(2)}%`
          : "0%";
    }

    setData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ws && ws.readyState === WebSocket.OPEN) {
      const transformPayload = (data) => {
        return {
          action: "EDIT_PERCENTAGE",
          payload: {
            regions: data.map((regionData) => ({
              region: regionData.region,
              maxParticipants: Number(regionData.maxParticipants),
              totalParticipants: Number(regionData.totalParticipants),
              attendedParticipants: Number(regionData.attendedParticipants),
            })),
          },
        };
      };

      const payload = transformPayload(data);

      ws.send(JSON.stringify(payload));
      console.log("Payload sent via WebSocket:", payload);

      // Listen for the WebSocket server response
      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);

        // Check if the response indicates success
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Submission Edit Successful",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Submission Failed",
            text: response.message || "An error occurred.",
            confirmButtonText: "OK",
          });
        }
      };
    } else {
      console.error("WebSocket is not connected.");
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "WebSocket is not connected.",
        confirmButtonText: "Try Again",
      });
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Region Form</h1>
      <form onSubmit={handleSubmit}>
        <table className="table-auto border-collapse border border-gray-400 w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-400 px-4 py-2">Region</th>
              <th className="border border-gray-400 px-4 py-2">
                Attended Participants
              </th>
              <th className="border border-gray-400 px-4 py-2">
                maxParticipants
              </th>
              <th className="border border-gray-400 px-4 py-2">Percentage</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row.region} className="text-center">
                <td className="border border-gray-400 px-4 py-2">
                  {row.region}
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <input
                    type="number"
                    value={row.attendedParticipants}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "attendedParticipants",
                        e.target.value
                      )
                    }
                    className="w-full p-1 border border-gray-300 rounded"
                  />
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  <input
                    type="number"
                    value={row.maxParticipants}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "maxParticipants",
                        e.target.value
                      )
                    }
                    className="w-full p-1 border border-gray-300 rounded"
                  />
                </td>
                <td className="border border-gray-400 px-4 py-2">
                  {row.percentage}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Admin;
