import Loading from "./layouts/Loading";
import Main from "./layouts/Main";
import CMS from "./layouts/Admin";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Thankyou from "./layouts/Thankyou";
import bgm from "./assets/music/bgm1.wav";
import useSound from "use-sound";

function App() {
  const [isLoading, setLoading] = useState(true);
  const [play, { stop }] = useSound(bgm, { loop: true, volume: 0.5 });

  useEffect(() => {
    play();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      stop();
    };
  }, [play, stop]);

  return (
    <div className="App flex justify-center items-center bg-[#f6dddf]">
      <Router>
        {isLoading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/thankyou" exact element={<Thankyou />} />
            <Route path="/cms" element={<CMS />} />
            <Route path="/loading" element={<Loading />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
