import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import backgroundImage from "../assets/img/background.jpg";
import baseInput from "../assets/img/baseinput.png";
import submitButton from "../assets/img/btnidle.png";
import submitButtonClicked from "../assets/img/btnclicked.png";
import { useNavigate } from "react-router-dom";
import sfxBtn from "../assets/music/sfxbtn1.wav";
import useSound from "use-sound";

function Main() {
  const [formData, setFormData] = useState({
    name: "",
    region: "",
  });
  const [ws, setWs] = useState(null);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const navigate = useNavigate();
  const [playSfx] = useSound(sfxBtn, { volume: 4.2 });

  const regions = [
    "HeadOffice",
    "Sumbagut",
    "Sumbateng",
    "Sumbaksel",
    "Jabotabek 1",
    "Jabotabek 2",
    "Jawa Barat",
    "Jawa Tengah",
    "Jawa Timur",
    "BNT",
    "Kalimantan",
    "Sultan",
    "Pasima",
  ];

  // Initialize WebSocket connection
  useEffect(() => {
    const socket = new WebSocket(
      "wss://server-adirakickoff2025.senimankode.id/"
    );
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Clean up WebSocket connection
    return () => {
      socket.close();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitBtnClicked(true);
    playSfx();

    setTimeout(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        const payload = {
          action: "SUBMIT_PARTICIPANT",
          payload: {
            name: formData.name,
            region: formData.region,
          },
        };

        ws.send(JSON.stringify(payload));
        console.log("Payload sent via WebSocket:", payload);

        Swal.fire({
          icon: "success",
          title: "Terimakasih sudah datang",
          html: `<strong>Nama :</strong> ${formData.name}<br><strong>Wilayah :</strong> ${formData.region}`,
          confirmButtonText: "OK",
        }).then(navigate("/thankyou"));
      } else {
        console.error("WebSocket is not connected.");
        Swal.fire({
          icon: "error",
          title: "Pengisian form gagal",
          text: "WebSocket is not connected.",
          confirmButtonText: "Try Again",
        });
        setSubmitBtnClicked(false);
      }
      setFormData({ name: "", region: "" });
    }, 500);
  };

  return (
    <div
      className="flex flex-col pt-32 justify-center items-center bg-[#f5a2d3] min-h-screen w-screen font-fontadira"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <header className="">
        <div className="space-y-[-5px] flex flex-col items-center text-[24px]">
          <h1 className="text-white custom-contour text-center">
            SAYA GARDIRA LEADER
          </h1>
          <h1 className="text-white custom-contour text-center">
            SIAP UNTUK MENGAMALKAN
          </h1>
          <h1 className="text-white custom-contour text-center">
            5 SILA ADIRA FINANCE
          </h1>
        </div>
        <form className="space-y-10 px-2 mt-5" onSubmit={handleSubmit}>
          <div className="flex flex-col text-center">
            <label
              className="text-yellow-300 custom-contour-yellow text-[24px]"
              htmlFor="name"
            >
              Nama
            </label>
            <input
              style={{
                backgroundImage: `url(${baseInput})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              id="name"
              name="name"
              type="text"
              className="p-3 text-[24px] w-full h-12 bg-gray-300"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col text-center">
            <label
              className="text-yellow-300 custom-contour-yellow text-[24px]"
              htmlFor="region"
            >
              Wilayah
            </label>
            <select
              style={{
                backgroundImage: `url(${baseInput})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              id="region"
              name="region"
              className="p-3 text-[24px] w-full h-12"
              value={formData.region}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a region
              </option>
              {regions.map((region) => (
                <option key={region} value={region.toUpperCase()}>
                  {region.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="flex items-center justify-center text-white custom-contour"
              style={{
                backgroundImage: `url(${
                  submitBtnClicked ? submitButtonClicked : submitButton
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "150px",
                height: "40px",
              }}
            >
              <p className="text-[24px]">KIRIM</p>
            </button>
          </div>
        </form>
      </header>
    </div>
  );
}

export default Main;
